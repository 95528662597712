<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="平台名称">
            <el-input v-model="searchForm.name" placeholder="请输入 平台名称"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="平台名称" prop="name" :rules="[{ required: true, message: '请输入 平台名称' }]">
              <el-input clearable v-model="form.name" placeholder="请输入 平台名称" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="状态" prop="state" :rules="[{ required: true, message: '请选择 状态' }]">
              <el-switch v-model="form.state"  active-text="开启" inactive-text="禁用" :active-value="1" :inactive-value="2"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="描述" prop="msg">
              <el-input v-model="form.msg" :rows="2" type="textarea" placeholder="请输入描述"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="平台路径" prop="url">
              <el-input v-model="form.url" placeholder="请输入 平台路径"/>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark" :rows="2" type="textarea" placeholder="请输入备注"/>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSave">提交</el-button>
        </div>
      </template>
    </el-dialog>

  </div>
</template>
<script>
import { communityAll } from "@/api/popularization/build";
import { dataList, addData, editData, dataDel } from "@/api/outside/terrace";
import Searchbar from '@/components/Searchbar';
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components:{
    Searchbar,
  },
  data(){
    return{
      dialogFormVisible: false,
      title:"新增",
      communityList:[],
      form:{
        state: 2
      },
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "name",
          label: "平台名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "state",
          label: "状态",
          render: (row) => <div>{ row.state == 1 ? '开启' : '禁用' }</div>,
        },
        {
          prop: "url",
          label: "路径",
          render: (row) => <div>{row.url}</div>,
        },
        {
          prop: "msg",
          label: "描述",
          render: (row) => <div>{row.msg}</div>,
        },
        {
          prop: "remark",
          label: "备注",
          render: (row) => <div>{row.remark}</div>,
        },
        {
          prop: "addtime",
          label: "创建时间",
          render: (row) => <div>{row.addtime}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={() => this.onEdit(row)}>编辑</el-button>
                <el-button type="text" icon="Delete" onClick={() => this.onDel(row)}>删除</el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  mounted() {
    communityAll().then(res=>{
      this.communityList = res.data.data;
    })
  },
  watch:{
  },
  created() {
    this.onLoad();
  },
  methods:{
    handleClose(done){
      this.$nextTick(()=>{
        this.$refs.dataform.resetFields();
        this.form = { state: 2 };
      })
      done();
    },

    onEdit(row){
      this.dialogFormVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
      this.title = '编辑';
    },

    onDel(row){
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
            return dataDel({ id: row.id });
          }).then( res => {
        this.onLoad();
        ElMessage({
          message: "操作成功",
          type: "success"
        });
      });
    },

    onSave(){
      this.$refs['dataform'].validate(async (valid) => {
        let { id, name, url, msg, state, remark } = this.form;
        if (valid) {
          if(this.form.id){
            let res = await editData({
              id, name, url, msg, state, remark
            });
            this.$message.success("操作成功");
            this.dialogFormVisible = false;
            this.onLoad();
          }else{
            let res = await addData({
              name, url, msg, state, remark
            });
            this.$message.success("操作成功");
            this.dialogFormVisible = false;
            this.onLoad();
          }

          this.$nextTick(()=>{
            this.$refs.dataform.resetFields();
            this.form = { state: 2 };
          })
        } else {
          return false;
        }
      });
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad();
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },


    onLoad(){
      dataList({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tab{
  width: 100%;
  display: flex;
  flex-direction: column;
  >article{
    font-size: 20px;
    //padding: 0 50px;
    border: 1px solid;
    border-bottom: none;
    display: flex;
    align-items: center;
    &:last-child{
      border-bottom: 1px solid;
    }
    section{
      padding: 10px;
      &:nth-child(1){
        width: 140px;
        text-align: center;
      }
      &:nth-child(2){
        border-left: 1px solid;
        width: calc(100% - 140px);
        text-align: left;
      }
    }
  }
}
</style>